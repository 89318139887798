.our-view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 3%;
    font-family: 'Poppins', sans-serif;
    padding-bottom: 10%;

}

.our-view h2 {
    padding-top: 5%;
    padding-bottom: 3%;
    font-weight: 600;
}

.text {
    color: black;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    flex-wrap: nowrap;
    align-items: center;
    background-color: rgb(243, 241, 241);



}

.text p {
    width: 90%;
    max-width: 600px;
    min-width: 290px;
    padding: 2%;
    padding-top: 3%;
    margin: auto;
    text-align: center;
    background-color: rgb(243, 241, 241);
    font-size: clamp(16px, 1.2vw, 20px);

}

.our-view img {
    width: 90%;
    max-width: 600px;
    min-width: 290px;

}

@media (max-width: 991px){
    .text{
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        background-color: transparent;
       }
 }