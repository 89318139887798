.callback-services {
	border-top: 1px solid #eee;
}

.callback-services .container{
    background-color: rgb(243, 241, 241);

}
.contact-us {
	background-color: #f7f7f7;
	padding: 140px 0px;
}

.contact-us .contact-form {
	background-color: transparent!important;
	padding: 0!important;
}

.callback-form {
    padding-top: 5%;

	padding-bottom: 3%;
    background-color: rgb(243, 241, 241);

}

.callback-form h2{
    padding-bottom: 3%;
}

.callback-form .contact-form {
	background-color: var(--main-color);
	padding: 60px;
	border-radius: 5px;
	text-align: center;
}

.callback-form .contact-form input {
	border-radius: 20px;
	height: 40px;
	line-height: 40px;
	display: inline-block;
	padding: 0px 15px;
	color: #6a6a6a;
	font-size: 13px;
	text-transform: none;
	box-shadow: none;
	border: none;
	margin-bottom: 35px;
}

.callback-form .contact-form input:focus {
	outline: none;
	box-shadow: none;
	border: none;
}

.callback-form .contact-form textarea {
	border-radius: 20px;
	height: 120px;
	max-height: 200px;
	min-height: 120px;
	display: inline-block;
	padding: 15px;
	color: #6a6a6a;
	font-size: 13px;
	text-transform: none;
	box-shadow: none;
	border: none;
	margin-bottom: 35px;
}

.callback-form .contact-form textarea:focus {
	outline: none;
	box-shadow: none;
	border: none;
}

.callback-form .contact-form ::-webkit-input-placeholder { /* Edge */
  color: #aaa;
}
.callback-form .contact-form :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #aaa;
}
.callback-form .contact-form ::placeholder {
  color: #aaa;
}

.callback-form .contact-form button.border-button {
	background-color: transparent;
	color: #fff;
	border: 2px solid #fff;
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 700;
	padding: 10px 28px;
	border-radius: 30px;
	display: inline-block;
	transition: all 0.3s;
	outline: none;
	box-shadow: none;
	text-shadow: none;
	cursor: pointer;
}

.callback-form .contact-form button.border-button:hover {
	background-color: #fff;
	color: var(--main-color);
}

.contact-us .contact-form button.filled-button {
	background-color: var(--main-color);
	color: #fff;
	border: none;
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 700;
	padding: 12px 30px;
	border-radius: 30px;
	display: inline-block;
	transition: all 0.3s;
	outline: none;
	box-shadow: none;
	text-shadow: none;
	cursor: pointer;
}

.contact-us .contact-form button.filled-button:hover {
	background-color: #fff;
	color: var(--main-color);
}

