.contact-information {
	padding-top: 10%;
    padding-bottom: 10%;

}

.contact-information .row{
    justify-content: center;
}

.contact-information svg{
    height: 40px;
    fill: var(--main-color);
    padding-bottom: 3%;
}
.contact-information .contact-item {
	padding: 60px 30px;
	background-color: #f7f7f7;
	text-align: center;
    margin-bottom: 3%;
}

.contact-information .contact-item i {
	color: var(--main-color);
	font-size: 48px;
	margin-bottom: 40px;
}

.contact-information .contact-item h4 {
	font-size: 20px;
	font-weight: 700;
	letter-spacing: 0.25px;
	margin-bottom: 15px;
}

.contact-information .contact-item p {
	margin-bottom: 20px;
}

.contact-information .contact-item a {
	font-weight: 600;
	color: var(--main-color);
	font-size: 15px;
}


@media(max-width: 991px){
    .contact-information .row{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .col-md-4{
        width:100%;
    }
}