:root {
  --main-color: #6baef7;
}

.landing {
  height: max-content;
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
  width: 100%;
  padding-bottom: 5%;
}

em {
  color: var(--main-color);
  font-style: normal;
  font-weight: bold;
}

a.filled-button {
  background-color: var(--main-color);
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 12px 30px;
  border-radius: 30px;
  display: inline-block;
  transition: all 0.3s;
  text-decoration: none;
}

a.filled-button:hover {
  background-color: #fff;
  color: var(--main-color)
}

a.border-button {
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 28px;
  border-radius: 30px;
  display: inline-block;
  transition: all 0.3s;
}

a.border-button:hover {
  background-color: #fff;
  color: var(--main-color)
}


.landing {
  background-image: url(./financial_background.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #FFF;
  overflow: hidden;
  position: relative;
}


.text-content {
  text-align: left;
  width: 75%;
  justify-self: center;
  margin: auto;

}

.text-content h6 {
  margin-top: 5%;
  margin-bottom: 15px;
  font-size: clamp(10px, 22px, 30px);
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  color: var(--main-color);
}

.text-content h4 {
  margin-bottom: 30px;
  text-transform: uppercase;
  font-size: clamp(2rem, 5vw, 3rem);
  font-weight: 700;
  letter-spacing: 2.5px;
  color: #FFF;
  overflow: hidden;
}

.text-content p {
  max-width: 570px;
  color: #fff;
  font-size: clamp(0.8rem, 2vw, 15px);
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 40px;
}