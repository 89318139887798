footer {
	background-color: #232323;
	padding: 80px 0px 10px;
	color: #fff;
}

footer li:hover{
    color: grey;
}

footer ul {
    padding-left: 0;
}

footer li{
    list-style-type: none;
}


footer h4 {
	color: #fff;
	font-size: 20px;
	font-weight: 600;
	letter-spacing: 0.25px;
	margin-bottom: 35px;
}
footer p {
	color: #fff;
}

footer ul.social-icons {
	margin-top: 25px;
}

footer ul.social-icons li {
	display: inline-block;
	margin-right: 5px;
}

footer ul.social-icons li:last-child {
	margin-right: 0px;
}

footer ul.social-icons li a {
	width: 34px;
	height: 34px;
	display: inline-block;
	line-height: 34px;
	text-align: center;
	background-color: #fff;
	color: #232323;
	border-radius: 50%;
	transition: all 0.3s;
}

footer ul.social-icons li a:hover {
	background-color: var(--main-color);
}

footer ul.menu-list li {
	margin-bottom: 13px;
}

footer ul.menu-list li:last-child {
	margin-bottom: 0px;
}

footer ul.menu-list li a {
	font-size: 14px;
	color: #fff;
	transition: all 0.3s;
}

footer ul.menu-list li a:hover {
	color: var(--main-color);
}

.footer-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Poppins', sans-serif;
}

footer .contact-form input {
	border-radius: 20px;
	height: 40px;
	line-height: 40px;
	display: inline-block;
	padding: 0px 15px;
	color: #aaa!important;
	background-color: #343434;
	font-size: 13px;
	text-transform: none;
	box-shadow: none;
	border: none;
	margin-bottom: 15px;
}

footer .contact-form input:focus {
	outline: none;
	box-shadow: none;
	border: none;
	background-color: #343434;
}

footer .contact-form textarea {
	border-radius: 20px;
	height: 120px;
	max-height: 200px;
	min-height: 120px;
	display: inline-block;
	padding: 15px;
	color: #aaa!important;
	background-color: #343434;
	font-size: 13px;
	text-transform: none;
	box-shadow: none;
	border: none;
	margin-bottom: 15px;
}

footer .contact-form textarea:focus {
	outline: none;
	box-shadow: none;
	border: none;
	background-color: #343434;
}

footer .contact-form ::-webkit-input-placeholder { /* Edge */
  color: #aaa;
}
footer .contact-form :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #aaa;
}
footer .contact-form ::placeholder {
  color: #aaa;
}

footer .contact-form button.filled-button {
	background-color: transparent;
	color: #fff;
	background-color: var(--main-color);
	border: none;
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 700;
	padding: 12px 30px;
	border-radius: 30px;
	display: inline-block;
	transition: all 0.3s;
	outline: none;
	box-shadow: none;
	text-shadow: none;
	cursor: pointer;
}

footer .contact-form button.filled-button:hover {
	background-color: #fff;
	color: var(--main-color);
}

@media(max-width: 767px){
    .footer-item ul.menu-list{
        display: flex;
        flex-direction: row;
        gap: 5%;
        justify-content: center;
      
    }

    footer .row .footer-item{
        padding-top: 5%;
    }
}
.copyright{
	padding-top: 4%;
}
