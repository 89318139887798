.single-services {
    margin-top: 2%;
    padding-bottom: 5%;
}

#tabs ul {
    margin: 0;
    padding: 0;
}



#tabs ul li {
    margin-bottom: 10px;
    display: inline-block;
    width: 100%;
}

#tabs ul li:last-child {
    margin-bottom: 0px;
}

#tabs ul li a {
    text-transform: capitalize;
    width: 100%;
    padding: 30px 30px;
    display: inline-block;
    background-color: var(--main-color);
    font-size: 20px;
    color: #121212;
    letter-spacing: 0.5px;
    font-weight: 700;
    transition: all 0.3s;
}

#tabs ul li a i {
    float: right;
    margin-top: 5px;
}

#tabs ul .ui-tabs-active span {
    background: #faf5b2;
    border: #faf5b2;
    line-height: 90px;
    border-bottom: none;
}

#tabs ul .ui-tabs-active a {
    color: #fff;
}

#tabs ul .ui-tabs-active span {
    color: #1e1e1e;
}

.tabs-content {
    padding: 5%;
    text-align: left;
    transition: all 0.3s;
    flex-direction: column;
}

.tabs-content img {
    width: 100%;
    overflow: hidden;
    max-width: 400px;
}

.tabs-content article{
    display: flex;
    flex-direction: column;
}

#tabs .row{
    height: max-content;
}


.tabs-content h4 {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.25px;
    margin-bottom: 15px;
    margin-top: 30px;
}

.tabs-content p {
    font-size: 18px;
    color: #7a7a7a;
    margin-bottom: 0px;
    max-width: 70%;
}

.tabs-content li p::before {
    content: "•";

}

@media(max-width: 991px) {
    .row {
        justify-content: center;
    }


    #tabs ul li a {
        width: 100%;
    }

    #tabs ul {
        gap: 2%;
        margin: auto;
    }

    #tabs ul li {
        display: flex;
    }

    .tabs-content {
        padding-top: 15%;
    }

    .tabs-content p {
        max-width: 100%;
    }
}