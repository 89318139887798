:root{
	--main-color: #6baef7;
}
.request-form {
	background-color: var(--main-color);
	padding: 40px 0px;
	color: #fff;
    font-family: 'Poppins', sans-serif;
    
}

.request-form h4 {
	font-size: 22px;
	font-weight: 600;
}

.request-form span {
	font-size: 15px;
	font-weight: 400;
	display: inline-block;
	margin-top: 10px;
}

.request-form a.border-button {
	margin-top: 12px;
	float: right;
}

a.filled-button, a.border-button {
	background-color: var(--main-color);
	color: #fff;
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 700;
	padding: 12px 30px;
	border-radius: 30px;
	display: inline-block;
	transition: all 0.3s;
  text-decoration: none;
}

a.filled-button:hover, a.border-button:hover {
	background-color: #fff;
	color: var(--main-color)
}

a.border-button {
	background-color: transparent;
	color: #fff;
	border: 2px solid #fff;
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 700;
	padding: 10px 28px;
	border-radius: 30px;
	display: inline-block;
	transition: all 0.3s;
}

a.border-button:hover {
	background-color: #fff;
	color: var(--main-color)
}


@media(max-width: 991px){
    .request-form a.border-button {
        margin-top: 12px;
        float: none;
    }

	.request-form h4{
		align-self: center;
	}

	.request-form .row{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}
