a{
    cursor: pointer;
}

nav {
    z-index: 99999;
    width: 100%;
    background-color: transparent;
    height: auto;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    align-items: center;
    font-family: 'Poppins', sans-serif;
}

nav .navbar {
    padding: 20px 0px;
    align-items: center;
}

.container-fluid{
    align-items: center;
}

.navbar {
    padding: 10px 0px;
    background-color: transparent;
}

nav {
    background-color: transparent;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
}

.navbar-brand h2 {
    color: var(--main-color) !important;
}

.navbar-nav a.nav-link {
    color: white !important;
}

.navbar-nav .nav-link:hover,
.navbar-nav .active>.nav-link,
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show,
.navbar-nav .show>.nav-link {
    color: var(--main-color) !important;
}


.navbar .navbar-brand {
    color: #fff;

    text-transform: uppercase;
    font-size: clamp(1.1rem, 3.6vw, 30px);
    font-weight: 700;
    background-color: transparent;
    margin-left: 5%;
}

.navbar .navbar-toggler {
    border-color: white;
    background-color: transparent;
    height: 36px;
    outline: none;
    border-radius: 10px;
    right: 30px;
    top: 20px;
    align-self: center;
}

.navbar-collapse{
  justify-content: center;
}

.navbar-nav{
    gap: 15%;
}

/*

.navbar .navbar-brand h2 em {
    font-style: normal;
    font-size: 16px;
}

#navbarResponsive {
    z-index: 999;
}

.navbar-collapse {
    text-align: center;
}

.navbar .navbar-nav .nav-item {
    margin: 0px 15px;
}

.navbar .navbar-nav a.nav-link {
    text-transform: capitalize;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 0.5px;
    color: #fff;
    transition: all 0.5s;
    margin-top: 5px;
}

} */



.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .active>.nav-link,
.navbar .navbar-nav .nav-link.active,
.navbar .navbar-nav .nav-link.show,
.navbar .navbar-nav .show>.nav-link {
    color: var(--main-color);
}