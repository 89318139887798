.about-head{
    background-image: url(./abouthead.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    height: 80vh;
    display: flex;
    flex-direction: column;
}

.about-head .container {
    justify-self: center;
    align-self: center;
    margin: auto;
}

.about-head h1 {
    background-color: rgba(0, 0, 0, 0.7);
    width: auto;
    margin: auto;
    border-radius: 10px;
    color: white;
    padding: 10px;
    box-shadow: -1px -1px 7px 2px rgba(0, 0, 0, 0.6);

}